import { template as template_809d08d599db49b5a1bf1bd7be41e0ec } from "@ember/template-compiler";
const FKControlMenuContainer = template_809d08d599db49b5a1bf1bd7be41e0ec(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
