import { template as template_7be78ce4de6146fbb20706954426cfed } from "@ember/template-compiler";
const FKText = template_7be78ce4de6146fbb20706954426cfed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
