import { template as template_c3ff82cb36ef484eb75357ce97b0a4a7 } from "@ember/template-compiler";
const FKLabel = template_c3ff82cb36ef484eb75357ce97b0a4a7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
