import { template as template_f9753ecdb4ac46819933a854ded6c616 } from "@ember/template-compiler";
const WelcomeHeader = template_f9753ecdb4ac46819933a854ded6c616(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
